import React, { useState } from 'react';
import { X } from 'lucide-react';
import { motion } from 'framer-motion';

// Интерфейс для описания структуры услуги
interface Service {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
}

// Массив услуг с обновленными путями к изображениям
const services: Service[] = [
  { 
    id: '1', 
    title: 'Экспедирование грузов', 
    description: 'Мы организуем и контролируем перевозку грузов, обеспечивая соблюдение сроков и условий доставки.',
    imageUrl: '/img-services/Экспедирование грузов.jpg'
  },
  { 
    id: '2', 
    title: 'Сборные грузы', 
    description: 'Мы оптимизируем маршруты и объединяем грузы, что позволяет экономить на логистике.',
    imageUrl: '/img-services/Сборный груз.jpg'
  },
  { 
    id: '3', 
    title: 'Переезд склада', 
    description: 'Имеем большой опыт в организации складских переездов, рассчитаем смету, составим график, минимизируем простои.',
    imageUrl: '/img-services/Переезд скалада.jpeg'
  },
  { 
    id: '4', 
    title: 'Транспортные услуги', 
    description: 'Предоставляем услуги по доставке грузов малотоннажным и крупнотоннажным автомобильным транспортом на территории РФ.',
    imageUrl: '/img-services/Транспортные услуги.jpg'
  },
  { 
    id: '5', 
    title: 'Аренда транспортных средств', 
    description: 'Мы поможем подобрать оптимальный вариант и обеспечить качественное обслуживание для перевозки Вашего груза.',
    imageUrl: '/img-services/Аренда транспорта.jpg'
  },
  { 
    id: '6', 
    title: 'Страхование грузов', 
    description: 'Мы предлагаем услуги страхования грузов, чтобы защитить вас от возможных убытков в случае повреждения или утраты груза во время перевозки.',
    imageUrl: '/img-services/Страхование груза.jpg'
  },
  { 
    id: '7', 
    title: 'Профессиональная доставка на Маркетплейсы', 
    description: 'Мы предлагаем комплексные решения по доставке товаров на крупнейшие маркетплейсы: WILDBERRIES, OZON, Яндекс.Маркет, СберМегаМаркет, Lamoda, Детский мир, Казань Экспресс.',
    imageUrl: '/img-services/Доставка на маркетплейсы.jpg'
  },
  { 
    id: '8', 
    title: 'Консолидация и хранение', 
    description: 'Мы организуем консолидацию грузов от различных отправителей на наших партнерских складах с последующей отправкой в транспортном средстве, что позволяет оптимизировать затраты и ускорить процесс доставки.',
    imageUrl: '/img-services/Консолидация и хранение.jpg'
  },
];

// Интерфейс пропсов для компонента ServiceCard
interface ServiceCardProps {
  title: string;
  description: string;
  imageUrl: string;
  onDetailsClick: () => void;
}

// Обновленный компонент карточки услуги с обработкой ошибок загрузки изображения
const ServiceCard: React.FC<ServiceCardProps> = ({ title, description, imageUrl, onDetailsClick }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [imageError, setImageError] = useState<boolean>(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <motion.div 
      className="rounded-lg shadow-md overflow-hidden cursor-pointer bg-white"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      whileHover={{ scale: 1.05 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <div className="relative h-48">
        {/* Затемняющий слой */}
        <div className="absolute inset-0 bg-black opacity-40 z-10"></div>
        {!imageError ? (
          <img 
            src={imageUrl} 
            alt={title} 
            className="w-full h-full object-cover object-center"
            onError={handleImageError}
          />
        ) : (
          <div className="w-full h-full bg-gray-300 flex items-center justify-center">
            <span className="text-gray-600">Изображение недоступно</span>
          </div>
        )}
        <motion.div 
          className="absolute inset-0 flex items-center justify-center p-4 z-20"
          initial={{ opacity: 1 }}
          animate={{ opacity: isHovered ? 0 : 1 }}
          transition={{ duration: 0.3 }}
        >
          <h3 className="text-white text-xl font-bold text-center">{title}</h3>
        </motion.div>
        <motion.div 
          className="absolute inset-0 bg-orange-500 bg-opacity-90 flex items-center justify-center p-4 z-20"
          initial={{ opacity: 0 }}
          animate={{ opacity: isHovered ? 1 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <p className="text-white text-sm text-center">{description}</p>
        </motion.div>
      </div>
      <div className="p-4">
        <motion.button 
          onClick={onDetailsClick}
          className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded transition-colors w-full"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Подробнее
        </motion.button>
      </div>
    </motion.div>
  );
};

// Интерфейс пропсов для компонента ContactForm
interface ContactFormProps {
  onClose: () => void;
}

// Компонент формы контакта
const ContactForm: React.FC<ContactFormProps> = ({ onClose }) => (
  <motion.div 
    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <motion.div 
      className="p-6 rounded-lg relative bg-white text-black"
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.9, opacity: 0 }}
    >
      <button onClick={onClose} className="absolute top-2 right-2">
        <X size={24} />
      </button>
      <h2 className="text-xl font-bold mb-4">Свяжитесь с нами</h2>
      <form className="space-y-4">
        <div>
          <label htmlFor="name" className="block mb-1">Имя</label>
          <input type="text" id="name" className="w-full border rounded px-2 py-1 bg-white text-black" />
        </div>
        <div>
          <label htmlFor="phone" className="block mb-1">Номер телефона</label>
          <input type="tel" id="phone" className="w-full border rounded px-2 py-1 bg-white text-black" />
        </div>
        <motion.button 
          type="submit" 
          className="bg-orange-500 hover:bg-orange-600 text-white px-4 py-2 rounded transition-colors"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Отправить
        </motion.button>
      </form>
    </motion.div>
  </motion.div>
);

// Обновленный главный компонент Services
const Services: React.FC = () => {
  const [isContactFormOpen, setIsContactFormOpen] = useState<boolean>(false);

  return (
    <div className="min-h-screen text-black flex items-center justify-center">
      <div className="container mx-auto p-4 max-w-6xl">
        <div className="mb-6 text-center">
          <h2 className="text-2xl font-bold bg-orange-500 text-white p-2 rounded inline-block">Наши услуги</h2>
        </div>
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ staggerChildren: 0.1 }}
        >
          {services.map((service) => (
            <ServiceCard
              key={service.id}
              title={service.title}
              description={service.description}
              imageUrl={service.imageUrl}
              onDetailsClick={() => setIsContactFormOpen(true)}
            />
          ))}
        </motion.div>
        {isContactFormOpen && <ContactForm onClose={() => setIsContactFormOpen(false)} />}
      </div>
    </div>
  );
};

export default Services;