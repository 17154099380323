import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ShoppingCart } from 'lucide-react';

const NavMenu: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const servicesRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (servicesRef.current && !servicesRef.current.contains(event.target as Node)) {
        setIsServicesOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const menuItems = [
    { name: 'Главная', path: '/' },
    { name: 'О нас', path: '/about' },
    { 
      name: 'Услуги', 
      path: '/services',
      subItems: [
        { name: 'Наши услуги', path: '/services' },
        // { name: 'Цена', path: '/price' },
        { name: 'Документы для перевозки', path: '/documents' }
      ]
    },
    { name: 'Контакты', path: '/contacts' },
  ];

  return (
    <nav className="bg-gray-800 text-white fixed top-0 left-0 right-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <Link to="/" className="flex items-center">
              <img src="/logo.png" alt="Logo" className="h-8 w-auto" />
            </Link>
          </div>
          <div className="flex-1 flex justify-center">
            <div className="hidden md:block">
              <div className="flex items-center space-x-4">
                {menuItems.map((item) => (
                  <div 
                    key={item.name} 
                    className="relative group"
                    onMouseEnter={() => item.subItems && setIsServicesOpen(true)}
                    onMouseLeave={() => item.subItems && setIsServicesOpen(false)}
                    ref={item.subItems ? servicesRef : null}
                  >
                    {item.subItems ? (
                      <>
                        <Link
                          to={item.path}
                          className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition duration-300 focus:outline-none"
                        >
                          {item.name}
                        </Link>
                        {isServicesOpen && (
                          <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5">
                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                              {item.subItems.map((subItem) => (
                                <Link
                                  key={subItem.name}
                                  to={subItem.path}
                                  className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white transition duration-150 ease-in-out"
                                  role="menuitem"
                                >
                                  {subItem.name}
                                </Link>
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <Link
                        to={item.path}
                        className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition duration-300"
                      >
                        {item.name}
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <Link
              to="/order"
              className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600 transition duration-300 text-sm font-medium flex items-center"
            >
              <ShoppingCart className="mr-2 h-4 w-4" />
              Оформить заказ
            </Link>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isMenuOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="md:hidden" id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {menuItems.map((item) => (
              <div key={item.name}>
                {item.subItems ? (
                  <>
                    <Link
                      to={item.path}
                      className="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium w-full text-left"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      {item.name}
                    </Link>
                    <div className="pl-4 bg-gray-700">
                      {item.subItems.map((subItem) => (
                        <Link
                          key={subItem.name}
                          to={subItem.path}
                          className="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-sm font-medium"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          {subItem.name}
                        </Link>
                      ))}
                    </div>
                  </>
                ) : (
                  <Link
                    to={item.path}
                    className="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {item.name}
                  </Link>
                )}
              </div>
            ))}

            <Link
              to="/order"
              className="flex items-center justify-center w-full mt-2 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600 transition duration-300"
              onClick={() => setIsMenuOpen(false)}
            >
              <ShoppingCart className="mr-2 h-4 w-4" />
              Оформить заказ
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavMenu;

//             <Link
// to="/calculator"
// className="flex items-center justify-center w-full mt-2 px-4 py-2 bg-blue-900 text-white rounded hover:bg-blue-800 transition duration-300"
// onClick={() => setIsMenuOpen(false)}
// >
// <Calculator className="mr-2 h-4 w-4" />
// Калькулятор
// </Link>

// <Link
// to="/calculator"
// className="px-4 py-2 bg-blue-900 text-white rounded hover:bg-blue-800 transition duration-300 text-sm font-medium flex items-center"
// >
// <Calculator className="mr-2 h-4 w-4" />
// Калькулятор
// </Link>