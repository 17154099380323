import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';

interface Slide {
  image: string;
  alt: {
    line1: string;
    line2: string;
  };
  description: string;
  infoBlock: string;
}

const slides: Slide[] = [
  {
    image: '/img-slider/mashinka-1.jpg',
    alt: {
      line1: 'Транспортно-экспедиционная компания',
      line2: 'ООО "ПАЛЕТКИН"'
    },
    description: 'Мы применяем современные технологии и опыт профессионалов рынка грузоперевозок для оптимизации логистических процессов, постоянно развиваемся и улучшаем наш сервис, нам доверяют поставщики и экономят свое время.',
    infoBlock: 'slider-info-block'
  },
  {
    image: '/img-slider/warehouse-1.jpg',
    alt: {
      line1: 'Транспортно-экспедиционная компания',
      line2: 'ООО "ПАЛЕТКИН"'
    },
    description: 'С ТЭК "ПАЛЛЕТКИН" вы получаете не просто услуги, а надежного партнера в мире логистики.',
    infoBlock: 'slider-info-block4'
  },
  {
    image: '/img-slider/palets.jpg',
    alt: {
      line1: 'Транспортно-экспедиционная компания',
      line2: 'ООО "ПАЛЕТКИН"'
    },
    description: 'Быстро развивающаяся логистическая компания, предлагаем широкий спектр качественных услуг в области грузоперевозок, доставки и курьерских отправлений, а также предоставляем сопутствующие сервисы.',
    infoBlock: 'slider-info-block2'
  },
  {
    image: '/img-slider/delivery.jpg',
    alt: {
      line1: 'Транспортно-экспедиционная компания',
      line2: 'ООО "ПАЛЕТКИН"'
    },
    description: 'Для получения консультации и дополнительной информации свяжитесь с нами сегодня или оставьте заявку - специалист свяжется с вами удобным способом.',
    infoBlock: 'slider-info-block3'
  }
];

const Slider: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentMobileSlide, setCurrentMobileSlide] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [direction, setDirection] = useState<'left' | 'right'>('right');

  const nextSlide = () => {
    setDirection('right');
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setDirection('left');
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const changeMobileSlide = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentMobileSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
      setIsAnimating(false);
    }, 500);
  };

  useEffect(() => {
    const timer = setInterval(nextSlide, 10000); // Увеличено с 5000 до 10000 мс
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const mobileTimer = setInterval(changeMobileSlide, 10000); // Увеличено с 5000 до 10000 мс
    return () => clearInterval(mobileTimer);
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log('Отправка формы', { name, phone });
    setIsModalOpen(false);
    setName('');
    setPhone('');
  };

  return (
    <div className="relative w-full max-w-[1000px] mx-auto overflow-hidden pt-16">
      {/* Мобильная версия */}
      <div className="md:hidden p-4">
        <div className="mb-4 h-[250px] relative overflow-hidden">
          <div className={`absolute w-full transition-all duration-1000 ease-in-out ${isAnimating ? 'opacity-0 transform -translate-y-full' : 'opacity-100 transform translate-y-0'}`}>
            <h2 className="text-xl font-bold text-orange-500 mb-2">
              {slides[currentMobileSlide].alt.line1}
            </h2>
            <h3 className="text-lg font-bold text-orange-500 mb-3">
              {slides[currentMobileSlide].alt.line2}
            </h3>
            <p className="text-sm">
              {slides[currentMobileSlide].description}
            </p>
          </div>
        </div>
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-orange-500 text-white px-3 py-2 text-xs rounded-lg hover:bg-orange-600 transition-colors duration-300 w-full"
        >
          Связаться с менеджером
        </button>
      </div>

      {/* Десктопная версия (слайдер) */}
      <div className="hidden md:block relative" style={{ aspectRatio: '16/9' }}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute top-0 left-0 w-full h-full transition-all duration-1000 ease-in-out ${
              index === currentSlide 
                ? 'opacity-100 transform scale-100' 
                : 'opacity-0 transform scale-110'
            } ${
              direction === 'right' 
                ? 'translate-x-0' 
                : '-translate-x-full'
            }`}
          >
            <img
              src={slide.image}
              alt={`${slide.alt.line1} ${slide.alt.line2}`}
              className="object-cover w-full h-full"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/60 to-black/70 flex flex-col items-center justify-center p-3 md:p-6">
              <div className="text-center mb-3 md:mb-4 max-w-3xl">
                <h2 className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold text-orange-500 mb-1 drop-shadow-lg animate-fade-in-down">
                  {slide.alt.line1}
                </h2>
                <h3 className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl font-bold text-orange-500 mb-2 drop-shadow-lg animate-fade-in-up">
                  {slide.alt.line2}
                </h3>
                <p className="text-xs sm:text-sm md:text-base lg:text-lg text-white drop-shadow-md overflow-y-auto max-h-[15vh] md:max-h-[25vh] animate-fade-in">
                  {slide.description}
                </p>
              </div>
              <button
                onClick={() => setIsModalOpen(true)}
                className="bg-orange-500 text-white px-3 py-2 md:px-4 md:py-2 text-xs md:text-sm rounded-lg hover:bg-orange-600 transition-all duration-300 transform hover:scale-105 shadow-lg animate-pulse"
              >
                Связаться с менеджером
              </button>
            </div>
          </div>
        ))}
        <button
          onClick={prevSlide}
          className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-orange-500"
        >
          <ChevronLeft size={24} />
        </button>
        <button
          onClick={nextSlide}
          className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full hover:bg-opacity-75 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-orange-500"
        >
          <ChevronRight size={24} />
        </button>
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentSlide(index)}
              className={`w-3 h-3 rounded-full transition-all duration-300 ${
                index === currentSlide ? 'bg-orange-500 scale-125' : 'bg-white'
              }`}
            />
          ))}
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 animate-fade-in">
          <div className="bg-white p-6 rounded-lg w-full max-w-md shadow-xl animate-scale-in">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold text-orange-500">Связаться с менеджером</h3>
              <button onClick={() => setIsModalOpen(false)} className="text-gray-500 hover:text-gray-700 transition-colors duration-300">
                <X size={24} />
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block mb-1 text-sm font-medium">Имя</label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full border rounded px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                />
              </div>
              <div>
                <label htmlFor="phone" className="block mb-1 text-sm font-medium">Номер телефона</label>
                <input
                  type="tel"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full border rounded px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-orange-500"
                  required
                />
              </div>
              <button type="submit" className="w-full bg-orange-500 text-white px-4 py-2 rounded text-sm hover:bg-orange-600 transition-all duration-300 transform hover:scale-105">
                Отправить
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Slider;