import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface MarketplaceLocation {
  marketplace: string;
  full_address: string;
}

interface GroupedAddresses {
  [key: string]: string[];
}

interface AddressSelectorProps {
  onSelect: (address: string) => void;
}

const API_URL = 'https://dev.paletkin.pro/api'; // Replace with your actual API URL

const AddressSelector: React.FC<AddressSelectorProps> = ({ onSelect }) => {
  const [expandedAddresses, setExpandedAddresses] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [customAddress, setCustomAddress] = useState('');
  const [groupedAddresses, setGroupedAddresses] = useState<GroupedAddresses>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAddresses = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get<MarketplaceLocation[]>(`${API_URL}/locations`);
        const grouped = response.data.reduce((acc: GroupedAddresses, location) => {
          if (!acc[location.marketplace]) {
            acc[location.marketplace] = [];
          }
          acc[location.marketplace].push(location.full_address);
          return acc;
        }, {});
        setGroupedAddresses(grouped);
      } catch (err) {
        setError('Ошибка при загрузке адресов. Пожалуйста, попробуйте позже.');
        console.error('Error fetching addresses:', err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAddresses();
  }, []);

  const toggleAddresses = () => {
    setExpandedAddresses(!expandedAddresses);
  };

  const handleAddressSelection = (address: string) => {
    if (address === 'Другой адрес') {
      setSelectedAddress('');
      setCustomAddress('');
    } else {
      setSelectedAddress(address);
      setCustomAddress('');
    }
    setExpandedAddresses(false);
    onSelect(address === 'Другой адрес' ? '' : address);
  };

  return (
    <div className="relative">
      <button
        type="button"
        onClick={toggleAddresses}
        className="w-full px-4 py-2 bg-orange-200 text-orange-800 rounded-md flex justify-between items-center hover:bg-orange-300 transition-colors duration-300"
        disabled={isLoading}
      >
        <span>{selectedAddress || customAddress || 'Выберите адрес'}</span>
        {expandedAddresses ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {isLoading && <p className="text-gray-500 mt-2">Загрузка адресов...</p>}
      {error && <p className="text-red-500 mt-2">{error}</p>}
      {expandedAddresses && !isLoading && !error && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto">
          {Object.entries(groupedAddresses).map(([marketplace, addresses]) => (
            <div key={marketplace}>
              <h4 className="font-semibold p-2 bg-gray-100">{marketplace}</h4>
              {addresses.map((address, index) => (
                <button
                  key={index}
                  type="button"
                  className="w-full text-left p-2 hover:bg-orange-100 transition-colors duration-300"
                  onClick={() => handleAddressSelection(address)}
                >
                  {address}
                </button>
              ))}
            </div>
          ))}
          <button
            type="button"
            className="w-full text-left p-2 hover:bg-orange-100 transition-colors duration-300 font-semibold"
            onClick={() => handleAddressSelection('Другой адрес')}
          >
            Другой адрес
          </button>
        </div>
      )}
      {selectedAddress === '' && (
        <input
          type="text"
          value={customAddress}
          onChange={(e) => {
            setCustomAddress(e.target.value);
            onSelect(e.target.value);
          }}
          placeholder="Введите свой адрес"
          className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-orange-500 focus:border-orange-500 w-full mt-2"
        />
      )}
    </div>
  );
};

export default AddressSelector;