import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface User {
  name: string;
  email: string;
  avatar: string;
  joinDate: string;
}

interface UserStats {
  orders: number;
  totalSpent: number;
}

type AuthMode = 'login' | 'register' | 'dashboard' | 'loading';

const CarAnimation: React.FC = () => (
  <motion.svg
    width="100"
    height="60"
    viewBox="0 0 100 60"
    initial="start"
    animate="end"
    style={{ margin: 'auto', display: 'block' }}
  >
    <motion.path
      d="M10,40 L20,40 L30,30 L70,30 L80,40 L90,40 L90,50 L10,50 Z"
      fill="#FF6600"
      stroke="#000000"
      strokeWidth="2"
    />
    <motion.circle cx="25" cy="50" r="8" fill="#333" />
    <motion.circle cx="75" cy="50" r="8" fill="#333" />
    <motion.rect x="60" y="15" width="15" height="15" fill="#99CCFF" />
    <motion.animate
      attributeName="x"
      values="0;100;0"
      dur="2s"
      repeatCount="indefinite"
    />
  </motion.svg>
);

const Account: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [stats, setStats] = useState<UserStats>({
    orders: 5,
    totalSpent: 15000
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [authMode, setAuthMode] = useState<AuthMode>('login');
  const [authData, setAuthData] = useState({ email: '', password: '', name: '' });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (isEditMode) {
      setUser(prevUser => prevUser ? { ...prevUser, [name]: value } : null);
    } else {
      setAuthData(prevData => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setAuthMode('loading');
    
    // Имитация задержки загрузки
    await new Promise(resolve => setTimeout(resolve, 2000));

    if (isEditMode) {
      setIsEditMode(false);
      console.log('Обновленные данные пользователя:', user);
      setAuthMode('dashboard');
    } else if (authMode === 'login' || authMode === 'register') {
      console.log(authMode === 'login' ? 'Вход:' : 'Регистрация:', authData);
      setUser({
        name: authData.name || 'Иван Иванов',
        email: authData.email,
        avatar: '/api/placeholder/80/80',
        joinDate: new Date().toLocaleDateString()
      });
      setAuthMode('dashboard');
    }
  };

  const renderAuthForm = () => (
    <motion.form
      onSubmit={handleSubmit}
      className="space-y-4 bg-white p-8 rounded-lg shadow-md"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <AnimatePresence>
        {authMode === 'register' && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Имя</label>
            <input
              type="text"
              id="name"
              name="name"
              value={authData.name}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50 transition-all duration-300"
              required
            />
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.1 }}
      >
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={authData.email}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50 transition-all duration-300"
          required
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Пароль</label>
        <input
          type="password"
          id="password"
          name="password"
          value={authData.password}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50 transition-all duration-300"
          required
        />
      </motion.div>
      <motion.button
        type="submit"
        className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 transition-all duration-300"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {authMode === 'login' ? 'Войти' : 'Зарегистрироваться'}
      </motion.button>
    </motion.form>
  );

  const renderDashboard = () => (
    <motion.div
      className="bg-white shadow-md rounded-lg overflow-hidden"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="md:flex">
        <div className="md:flex-shrink-0 p-6">
          <motion.img
            className="h-32 w-32 rounded-full mx-auto md:mx-0"
            src={user?.avatar}
            alt={user?.name}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          />
        </div>
        <div className="p-6">
          <motion.h2
            className="text-2xl font-semibold"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            {user?.name}
          </motion.h2>
          <motion.p
            className="text-gray-600"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            {user?.email}
          </motion.p>
          <motion.p
            className="text-gray-500 text-sm mt-2"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            Дата регистрации: {user?.joinDate}
          </motion.p>
        </div>
      </div>
      
      <motion.div
        className="border-t border-gray-200 px-6 py-4"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        <h3 className="text-xl font-semibold mb-4">Статистика</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600">Количество заказов</p>
            <p className="text-2xl font-bold">{stats.orders}</p>
          </div>
          <div>
            <p className="text-gray-600">Общая сумма покупок</p>
            <p className="text-2xl font-bold">{stats.totalSpent} ₽</p>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );

  return (
    <div className="container mx-auto p-4 bg-gray-100 min-h-screen">
      <motion.h1
        className="text-3xl font-bold mb-6 text-center text-gray-800"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Личный кабинет
      </motion.h1>
      
      {authMode === 'loading' ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <CarAnimation />
          <p className="text-center mt-4 text-gray-600">Загрузка данных...</p>
        </motion.div>
      ) : authMode === 'dashboard' && user ? (
        renderDashboard()
      ) : (
        <div className="max-w-md mx-auto">
          <div className="flex justify-center space-x-4 mb-4">
            <motion.button
              onClick={() => setAuthMode('login')}
              className={`px-4 py-2 rounded transition-all duration-300 ${
                authMode === 'login'
                  ? 'bg-orange-600 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-orange-500 hover:text-white'
              }`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Вход
            </motion.button>
            <motion.button
              onClick={() => setAuthMode('register')}
              className={`px-4 py-2 rounded transition-all duration-300 ${
                authMode === 'register'
                  ? 'bg-orange-600 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-orange-500 hover:text-white'
              }`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Регистрация
            </motion.button>
          </div>
          {renderAuthForm()}
        </div>
      )}
    </div>
  );
};

export default Account;