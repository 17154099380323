import React, { useState, useEffect } from 'react';
import { Phone, Mail, Send, MessageCircle, Newspaper, MapPin } from 'lucide-react';

const Contacts2 = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const contacts = [
    { icon: Phone, label: 'Позвонить', href: 'tel:+79933430444' },
    { icon: Mail, label: 'Отправить email', href: 'mailto:example@example.com' },
    { icon: Send, label: 'Написать в Telegram', href: 'https://t.me/Paletkin' },
    { icon: MessageCircle, label: 'Написать в WhatsApp', href: 'https://wa.me/79933430444' },
    { icon: Newspaper, label: 'Следите за новостями в Telegram', href: 'https://t.me/paletkinNEWS' },
  ];

  const address = "105118, г. Москва, ул. Вольная д. 25, офис 2027";
  const mapUrl = `https://maps.google.com/?q=${encodeURIComponent(address)}`;

  return (
    <div className="p-4 rounded-lg shadow-md bg-white text-gray-800 mt-20"> {/* Добавлен класс mt-20 для отступа сверху */}
      <div className="flex flex-col items-center mb-6 relative">
        <h2 className="text-3xl font-bold mb-4 text-center transition-all duration-500 ease-in-out transform hover:scale-105">
          Наши контакты и ресурсы
        </h2>
      </div>
      <div className="flex flex-wrap justify-center items-center gap-4 mb-6">
        {contacts.map((contact, index) => (
          <a
            key={index}
            href={contact.href}
            className={`flex flex-col items-center justify-center p-3 rounded-lg transition-all duration-300 w-28 h-28 
              bg-orange-500 text-white hover:bg-orange-600
              hover:scale-110 hover:rotate-3 active:scale-95 transform ${
              isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
            }`}
            style={{ transitionDelay: `${index * 100}ms` }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <contact.icon size={28} className="mb-2" />
            <span className="text-xs text-center font-medium">{contact.label}</span>
          </a>
        ))}
      </div>
      <div className="text-center transform transition-all duration-500 ease-in-out hover:scale-105">
        <a 
          href={mapUrl}
          className="inline-flex items-center justify-center p-3 rounded-lg transition-all duration-300 
            bg-orange-500 text-white hover:bg-orange-600 hover:shadow-lg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MapPin size={24} className="mr-2 animate-bounce" />
          <span>{address}</span>
        </a>
      </div>
    </div>
  );
};

export default Contacts2;