import React from 'react';

const Error404: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      <div className="text-center">
        <h1 className="text-9xl font-bold text-gray-800">404</h1>
        <h2 className="text-6xl font-medium py-8">Страница не найдена</h2>
        <p className="text-2xl pb-8 px-12 font-medium">Извините, мы не можем найти страницу, которую вы ищете.</p>
        <button
          onClick={() => window.history.back()}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Вернуться назад
        </button>
      </div>
    </div>
  );
};

export default Error404;