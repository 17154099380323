import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import NavMenu from './components/NavMenu';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Contacts2 from './pages/Contacts2';
import Order from './pages/Order';
import Documets from './pages/Documents';
import Account from './pages/Account';
import Calculator from './pages/Calculator';
import Price from './pages/Price';
import Error404 from './pages/Error404';
import SiteMetadata from './components/SiteMetadata';

const App: React.FC = () => {
  return (
    <Router>
      <SiteMetadata 
        title="Paletkin.pro" 
        favicon="/logo mini.png"
      />
      <div className="flex flex-col min-h-screen">
        <NavMenu />
        <main className="flex-grow container mx-auto mt-4 p-4">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contacts" element={<Contacts2 />} />
            <Route path="/order" element={<Order />} />
            <Route path="/documents" element={<Documets />} />
            <Route path="/account" element={<Account />} />
            <Route path="/calculator" element={<Calculator />} />
            <Route path="/price" element={<Price />} />
            {/* Маршрут для обработки 404 ошибки */}
            <Route path="*" element={<Error404 />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;