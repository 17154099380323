import React from 'react';
import { DownloadIcon, PhoneIcon } from 'lucide-react';

interface PriceItem {
  service: string;
  price: number;
}

const mainServices: PriceItem[] = [
  { service: "Доставка по городу", price: 500 },
  { service: "Междугородняя доставка", price: 2000 },
  { service: "Международная доставка", price: 5000 },
];

const additionalServices: PriceItem[] = [
  { service: "Экспресс-доставка", price: 1000 },
  { service: "Складское хранение (в день)", price: 100 },
  { service: "Упаковка груза", price: 300 },
];

const PriceTable: React.FC<{ items: PriceItem[] }> = ({ items }) => (
  <table className="w-full border-collapse border border-gray-300 mb-4">
    <thead>
      <tr className="bg-gray-100">
        <th className="border border-gray-300 p-2">Услуга</th>
        <th className="border border-gray-300 p-2">Цена от (руб.)</th>
      </tr>
    </thead>
    <tbody>
      {items.map((item, index) => (
        <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
          <td className="border border-gray-300 p-2">{item.service}</td>
          <td className="border border-gray-300 p-2 text-right">{item.price}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const Price: React.FC = () => {
  const handleDownload = () => {
    console.log("Скачивание прайс-листа");
  };

  const handleContact = () => {
    console.log("Связь с менеджером");
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20"> {/* Добавлен отступ сверху */}
      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-2xl font-bold mb-6">Прайс-лист на услуги</h1>
        
        <button
          onClick={handleDownload}
          className="mb-6 flex items-center justify-center bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition duration-300"
        >
          <DownloadIcon className="mr-2" size={20} />
          Скачать прайс
        </button>

        <h2 className="text-xl font-bold mb-2">Основные услуги</h2>
        <PriceTable items={mainServices} />

        <h2 className="text-xl font-bold mb-2">Дополнительные услуги</h2>
        <PriceTable items={additionalServices} />

        <button
          onClick={handleContact}
          className="w-full flex items-center justify-center bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 transition duration-300 mt-6"
        >
          <PhoneIcon className="mr-2" size={20} />
          Связаться с менеджером
        </button>
      </div>
    </div>
  );
};

export default Price;