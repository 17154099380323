import React, { useState } from 'react';
import SenderInfo from '../components/SenderInfo';
import CargoInfo from '../components/CargoInfo';
import RouteInfo from '../components/RouteInfo';
import AdditionalInfo from '../components/AdditionalInfo';
import AdditionalServices from '../components/AdditionalServices';
import NotificationBanner from '../components/NotificationBanner';
import { sendToBitrix24 } from '../components/Bitrix24Integration';


interface OrderData {
  lastName: string;
  firstName: string;
  organization: string;
  phone: string;
  transportType: string;
  weight: string;
  volume: string;
  dimensions: string;
  pallets: string;
  boxes: string;
  from: string;
  to: string;
  date: string;
  time: string;
  additionalInfo: string;
  additionalServices: string[];
}

type SectionType = 'dedicated' | 'consolidated' | null;

interface NotificationProps {
  type: 'success' | 'error';
  message: string;
  onClose: () => void;
}

const Order: React.FC = () => {
  const [expandedSection, setExpandedSection] = useState<'dedicated' | 'consolidated' | null>(null);
  const [transportType, setTransportType] = useState<'dedicated' | 'consolidated' | null>(null);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [notification, setNotification] = useState<{ type: 'success' | 'error'; message: string } | null>(null);

  const toggleSection = (section: 'dedicated' | 'consolidated') => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const handleTransportTypeChange = (type: 'dedicated' | 'consolidated') => {
    setTransportType(type);
    setExpandedSection(type);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log('Начало обработки отправки формы');

    const formData = new FormData(event.currentTarget);
    const orderData: OrderData = {
      lastName: formData.get('lastName') as string,
      firstName: formData.get('firstName') as string,
      organization: formData.get('organization') as string,
      phone: formData.get('phone') as string,
      transportType: formData.get('transportType') as string,
      weight: formData.get('weight') as string,
      volume: formData.get('volume') as string,
      dimensions: formData.get('dimensions') as string,
      pallets: formData.get('pallets') as string,
      boxes: formData.get('boxes') as string,
      from: formData.get('from') as string,
      to: selectedAddress,
      date: formData.get('date') as string,
      time: formData.get('time') as string,
      additionalInfo: formData.get('additionalInfo') as string,
      additionalServices: [
        formData.get('insurance') ? 'Страхование груза' : '',
        formData.get('packaging') ? 'Дополнительная упаковка' : '',
        formData.get('pallets') ? 'Упаковка в паллеты' : '',
        formData.get('loading') ? 'Погрузка на месте' : '',
        formData.get('unloading') ? 'Разгрузка на месте' : '',
        formData.get('documents') ? 'Возврат документов' : '',
      ].filter(Boolean),
    };

    console.log('Собранные данные заказа:', JSON.stringify(orderData, null, 2));

    try {
      console.log('Отправка данных в Битрикс24');
      const result = await sendToBitrix24(orderData);
      console.log('Результат отправки:', JSON.stringify(result, null, 2));

      if (result.success) {
        console.log('Заказ успешно отправлен в Битрикс24:', result.data);
        setNotification({ type: 'success', message: 'Заказ успешно оформлен и отправлен!' });
      } else {
        console.error('Ошибка при оформлении заказа:', result.error);
        setNotification({ type: 'error', message: `Ошибка при оформлении заказа: ${result.error}` });
      }
    } catch (error) {
      console.error('Произошла ошибка при оформлении заказа:', error);
      setNotification({ type: 'error', message: 'Произошла ошибка при оформлении заказа. Пожалуйста, попробуйте еще раз.' });
    }
  };

  return (
    <div className="pt-20 px-4 sm:px-6 lg:px-8 max-w-2xl mx-auto">
      {notification && (
        <NotificationBanner
          type={notification.type}
          message={notification.message}
          onClose={() => setNotification(null)}
        />
      )}
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-orange-500">Оформление заказа</h2>
        
        <SenderInfo />
        
        <CargoInfo 
          transportType={transportType}
          expandedSection={expandedSection}
          onTransportTypeChange={handleTransportTypeChange}
          onToggleSection={toggleSection}
        />
        
        <RouteInfo onAddressSelect={setSelectedAddress} />
        
        <AdditionalInfo />
        
        <AdditionalServices />

        <div className="mt-8">
          <button 
            type="submit"
            className="w-full px-6 py-2 bg-orange-500 text-white font-semibold rounded-lg transition-colors duration-300 hover:bg-orange-600"
          >
            Оформить заказ
          </button>
        </div>
      </form>
    </div>
  );
};

export default Order;