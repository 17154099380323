import React from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface CargoInfoProps {
  transportType: 'dedicated' | 'consolidated' | null;
  expandedSection: 'dedicated' | 'consolidated' | null;
  onTransportTypeChange: (type: 'dedicated' | 'consolidated') => void;
  onToggleSection: (section: 'dedicated' | 'consolidated') => void;
}

const CargoInfo: React.FC<CargoInfoProps> = ({
  transportType,
  expandedSection,
  onTransportTypeChange,
  onToggleSection
}) => {
  const TransportInfo: React.FC<{ type: 'dedicated' | 'consolidated' }> = ({ type }) => (
    <div className="mt-4 space-y-4">
      {type === 'dedicated' && (
        <div>
          <select name="transportType" className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-orange-500 focus:border-orange-500">
            <option value="">Выберите тип транспорта</option>
            <option value="1.5m2">Груз до 1,5 м2</option>
            <option value="kabluk">Каблук (от 0,7 тонн, ТС от 1,7м2)</option>
            <option value="gazel">Газель (от 1,5 до 3 тонн, ТС 6-8 п.)</option>
            <option value="gazmax">Газ МАХ (3 тонны, ТС 10-12 п.)</option>
            <option value="tyagach">Тягач (5 тонн, ТС 12-18 п.)</option>
            <option value="odinochka">Одиночка (10 тонны, ТС 18-22 п.)</option>
            <option value="tyagach_pp">Тягач с п/п (20 тонны, ТС до 33 п.)</option>
          </select>
        </div>
      )}
      <div className="grid grid-cols-1 gap-4">
        <input type="text" name="weight" placeholder="Вес (кг)" className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-orange-500 focus:border-orange-500" />
        <input type="text" name="volume" placeholder="Объем (м³)" className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-orange-500 focus:border-orange-500" />
        <input type="text" name="dimensions" placeholder="Габариты (ДхВхШ см)" className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-orange-500 focus:border-orange-500" />
        <input type="text" name="pallets" placeholder="Кол-во паллет" className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-orange-500 focus:border-orange-500" />
        <input type="text" name="boxes" placeholder="Кол-во коробок" className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-orange-500 focus:border-orange-500" />
      </div>
    </div>
  );

  return (
    <div className="mb-6">
      <h3 className="font-semibold mb-2 text-gray-700">ИНФОРМАЦИЯ О ГРУЗЕ И ТРАНСПОРТЕ</h3>
      <div className="space-y-4">
        <div className="flex flex-col space-y-2">
          <label className="flex items-center">
            <input
              type="radio"
              name="transportType"
              value="dedicated"
              checked={transportType === 'dedicated'}
              onChange={() => onTransportTypeChange('dedicated')}
              className="mr-2"
            />
            Выделенный транспорт
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              name="transportType"
              value="consolidated"
              checked={transportType === 'consolidated'}
              onChange={() => onTransportTypeChange('consolidated')}
              className="mr-2"
            />
            Сборный груз
          </label>
        </div>
        
        {transportType && (
          <div className="mt-4">
            <button 
              type="button"
              className={`w-full px-4 py-2 rounded flex justify-between items-center ${expandedSection === transportType ? 'bg-orange-500 text-white' : 'bg-orange-200 text-orange-800'}`}
              onClick={() => onToggleSection(transportType)}
            >
              <span>{transportType === 'dedicated' ? 'Выделенный транспорт' : 'Сборный груз'}</span>
              {expandedSection === transportType ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
            </button>
            {expandedSection === transportType && <TransportInfo type={transportType} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default CargoInfo;