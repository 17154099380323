import axios from 'axios';

interface OrderData {
  lastName: string;
  firstName: string;
  organization: string;
  phone: string;
  transportType: string;
  weight: string;
  volume: string;
  dimensions: string;
  pallets: string;
  boxes: string;
  from: string;
  to: string;
  date: string;
  time: string;
  additionalInfo: string;
  additionalServices: string[];
}

const BITRIX24_WEBHOOK_URL = 'https://paletkinpro.bitrix24.ru/rest/357/ow2ull3ee20gjdmp/crm.deal.add.json';

export const sendToBitrix24 = async (orderData: OrderData): Promise<any> => {
  console.log('Начало отправки данных в Битрикс24');
  console.log('Полученные данные заказа:', JSON.stringify(orderData, null, 2));

  const { 
    lastName, 
    firstName, 
    organization, 
    phone, 
    transportType,
    weight,
    volume,
    dimensions,
    pallets,
    boxes,
    from,
    to,
    date,
    time,
    additionalInfo,
    additionalServices
  } = orderData;

  if (lastName && firstName && organization && phone && transportType) {
    try {
      const params = {
        "FIELDS[TITLE]": `Заказ от ${organization}`,
        "FIELDS[NAME]": firstName,
        "FIELDS[LAST_NAME]": lastName,
        "FIELDS[PHONE][0][VALUE]": phone,
        "FIELDS[COMPANY_TITLE]": organization,
        "FIELDS[UF_CRM_1624986225]": transportType,
        "FIELDS[UF_CRM_1624986241]": weight,
        "FIELDS[UF_CRM_1624986257]": volume,
        "FIELDS[UF_CRM_1624986273]": dimensions,
        "FIELDS[UF_CRM_1624986289]": pallets,
        "FIELDS[UF_CRM_1624986305]": boxes,
        "FIELDS[UF_CRM_1624986321]": from,
        "FIELDS[UF_CRM_1624986337]": to,
        "FIELDS[UF_CRM_1624986353]": date,
        "FIELDS[UF_CRM_1624986369]": time,
        "FIELDS[COMMENTS]": `
          Дополнительная информация: ${additionalInfo}
          Дополнительные услуги: ${additionalServices.join(', ')}
        `
      };

      console.log('Подготовленные параметры для отправки:', JSON.stringify(params, null, 2));
      console.log('URL для отправки:', BITRIX24_WEBHOOK_URL);

      const response = await axios.post(BITRIX24_WEBHOOK_URL, params);

      console.log('Статус ответа:', response.status);
      console.log('Заголовки ответа:', JSON.stringify(response.headers, null, 2));
      console.log('Тело ответа:', JSON.stringify(response.data, null, 2));

      if (response.status === 200) {
        console.log("Успешный ответ от API Битрикс24");
        return { success: true, data: response.data };
      } else {
        console.error("Ошибка при отправке в Битрикс24. Статус:", response.status, "Текст ошибки:", response.statusText);
        return { success: false, error: `API request failed. Status: ${response.status}` };
      }
    } catch (error) {
      console.error('Ошибка при отправке данных в Битрикс24:', error);
      if (axios.isAxiosError(error)) {
        console.error('Детали ошибки Axios:', {
          message: error.message,
          code: error.code,
          request: error.request,
          response: error.response ? {
            data: error.response.data,
            status: error.response.status,
            headers: error.response.headers
          } : 'Нет ответа'
        });
      }
      return { success: false, error: 'Error sending data to Bitrix24: ' + (error instanceof Error ? error.message : String(error)) };
    }
  } else {
    console.error('Неполные данные для отправки:', { lastName, firstName, organization, phone, transportType });
    return { success: false, error: 'Заполненны не все данные' };
  }
};