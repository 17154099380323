import React from 'react';

const AdditionalInfo: React.FC = () => {
  return (
    <div className="mb-6">
      <h3 className="font-semibold mb-2 text-gray-700">ДОПОЛНИТЕЛЬНАЯ ИНФОРМАЦИЯ</h3>
      <textarea 
        name="additionalInfo"
        placeholder="Добавить детали заказа (для уточнений менеджера)" 
        className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-orange-500 focus:border-orange-500 h-24"
      ></textarea>
    </div>
  );
};

export default AdditionalInfo;