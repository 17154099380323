import React from 'react';
import AddressSelector from '../components/AddressSelector';

interface RouteInfoProps {
  onAddressSelect: (address: string) => void;
}

const RouteInfo: React.FC<RouteInfoProps> = ({ onAddressSelect }) => {
  return (
    <div className="mb-6">
      <h3 className="font-semibold mb-2 text-gray-700">МАРШРУТ, ДАТА ОТПРАВКИ И МАРКИРОВКА</h3>
      <div className="space-y-4">
        <input type="text" name="from" placeholder="Откуда" className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-orange-500 focus:border-orange-500" />
        <AddressSelector onSelect={onAddressSelect} />
        <input type="date" name="date" className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-orange-500 focus:border-orange-500" />
        <input type="time" name="time" className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-orange-500 focus:border-orange-500" />
      </div>
    </div>
  );
};

export default RouteInfo;