import React from 'react';
import { FaWhatsapp, FaPhone, FaTelegram } from 'react-icons/fa';

interface FooterLink {
  href: string;
  label: string;
}

interface FooterColumn {
  links: FooterLink[];
}

const Footer: React.FC = () => {
  const footerColumns: FooterColumn[] = [
    {
      links: [
        { href: "/", label: "Главная" },
        { href: "/about", label: "О нас" },
        { href: "/services", label: "Услуги" },
        { href: "/contacts", label: "Контакты" },
      ],
    },
    {
      links: [
        // { href: "/vacancies", label: "Вакансии" },
        { href: "/documents", label: "Документы" },
        // { href: "/otz", label: "Оставить отзыв" },
        // { href: "/otz", label: "Цены" },
      ],
    },
    {
      links: [
        { href: "/order", label: "Оформить заявку" },
        // { href: "/account", label: "Личный кабинет" },
        // { href: "/calculator", label: "Калькулятор" },
      ],
    },
  ];

  const socialLinks: { href: string; label: string; icon: React.ReactNode }[] = [
    { href: "https://wa.me/79933430444", label: "WhatsApp", icon: <FaWhatsapp size={24} className="text-orange-500" /> },
    { href: "tel:+79933430444", label: "Телефон", icon: <FaPhone size={24} className="text-orange-500" /> },
    { href: "https://t.me/Paletkin", label: "Telegram", icon: <FaTelegram size={24} className="text-orange-500" /> },
  ];

  return (
    <footer className="bg-gray-800 text-white py-6 mt-auto">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-start">
          <div className="mb-4 md:mb-0">
            <h3 className="text-lg font-bold"> ТЭК ООО"Палеткин"</h3>
            <p className="text-sm text-gray-300">Оптимальные решения для сложных маршрутов</p>
          </div>
          <nav className="mb-4 md:mb-0 flex flex-wrap justify-start md:justify-center space-x-8">
            {footerColumns.map((column, columnIndex) => (
              <ul key={columnIndex} className="space-y-2">
                {column.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <a href={link.href} className="text-sm text-gray-300 hover:text-white transition duration-300">
                      {link.label}
                    </a>
                  </li>
                ))}
              </ul>
            ))}
          </nav>
          <div className="flex space-x-4">
            {socialLinks.map((link, index) => (
              <a
                key={index}
                href={link.href}
                className="text-orange-500 hover:text-orange-400 transition duration-300"
                aria-label={link.label}
              >
                {link.icon}
              </a>
            ))}
          </div>
        </div>
        <div className="mt-6 pt-4 border-t border-gray-700 text-center">
          <p className="text-xs text-gray-300">&copy; {new Date().getFullYear()} ТЭК ООО"Палеткин". Все права защищены.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;