import React from 'react';
import { CheckCircle, XCircle, X } from 'lucide-react';

interface NotificationProps {
  type: 'success' | 'error';
  message: string;
  onClose: () => void;
}

const NotificationBanner: React.FC<NotificationProps> = ({ type, message, onClose }) => {
  return (
    <div className={`fixed top-[100px] right-4 w-96 p-4 rounded-md shadow-md ${type === 'success' ? 'bg-green-100' : 'bg-red-100'}`}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {type === 'success' ? (
            <CheckCircle className="h-6 w-6 text-green-500 mr-2" />
          ) : (
            <XCircle className="h-6 w-6 text-red-500 mr-2" />
          )}
          <span className="font-semibold">{type === 'success' ? 'Успех' : 'Ошибка'}</span>
        </div>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <X className="h-5 w-5" />
        </button>
      </div>
      <p className="mt-2">{message}</p>
    </div>
  );
};

export default NotificationBanner;