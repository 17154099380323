import React, { useState, useEffect } from 'react';

interface Vehicle {
  name: string;
  cost: number;
}

const vehicles: Vehicle[] = [
  { name: 'Газель', cost: 20 },
  { name: 'Бычок', cost: 25 },
  { name: 'МАЗ', cost: 30 },
];

const Calculator: React.FC = () => {
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);
  const [pallets, setPallets] = useState<number>(0);
  const [fromAddress, setFromAddress] = useState<string>('');
  const [toAddress, setToAddress] = useState<string>('');
  const [distance, setDistance] = useState<number>(0);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [isCalculating, setIsCalculating] = useState<boolean>(false);

  useEffect(() => {
    if (selectedVehicle && distance > 0) {
      const vehicleCost = selectedVehicle.cost * distance;
      const palletCost = pallets * 10; // Предположим, что стоимость одного паллета - 10
      setTotalCost(vehicleCost + palletCost);
    }
  }, [selectedVehicle, pallets, distance]);

  const handleCalculate = () => {
    setIsCalculating(true);
    // Имитация задержки расчета
    setTimeout(() => {
      setDistance(Math.floor(Math.random() * 1000) + 100); // Имитация расчета расстояния
      setIsCalculating(false);
    }, 1000);
  };

  return (
    <div className="pt-20 px-4 flex justify-center items-start min-h-screen bg-gray-100">
      <div className="w-full max-w-md bg-white rounded-xl shadow-md p-6 space-y-4">
        <h2 className="text-2xl font-bold text-center text-orange-500 pb-2 relative">
          Калькулятор стоимости логистики
          <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-950"></span>
        </h2>
        
        <div>
          <label className="block text-sm font-medium text-gray-700">Выберите транспорт</label>
          <select 
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            onChange={(e) => setSelectedVehicle(vehicles.find(v => v.name === e.target.value) || null)}
          >
            <option value="">Выберите транспорт</option>
            {vehicles.map((vehicle) => (
              <option key={vehicle.name} value={vehicle.name}>
                {vehicle.name} - {vehicle.cost} руб/км
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Количество паллет</label>
          <input
            type="number"
            value={pallets}
            onChange={(e) => setPallets(parseInt(e.target.value) || 0)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Адрес отправки</label>
          <input
            type="text"
            value={fromAddress}
            onChange={(e) => setFromAddress(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Адрес доставки</label>
          <input
            type="text"
            value={toAddress}
            onChange={(e) => setToAddress(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <button 
          onClick={handleCalculate} 
          className={`w-full font-bold py-2 px-4 rounded transition-colors duration-300 ${
            isCalculating 
              ? 'bg-gray-500 text-white cursor-not-allowed' 
              : 'bg-orange-500 hover:bg-orange-600 text-white'
          }`}
          disabled={isCalculating}
        >
          {isCalculating ? 'Расчет...' : 'Рассчитать стоимость'}
        </button>

        {distance > 0 && (
          <div className="mt-4">
            <p className="text-lg font-semibold">Расстояние: {distance} км</p>
            <p className="text-lg font-semibold">Общая стоимость: {totalCost} руб</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Calculator;