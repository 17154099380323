import React, { useState } from 'react';
import { motion, AnimatePresence, Variants } from 'framer-motion';
import { Truck, Award, Users, Laptop, UserCheck, Clock, Shield, Smile, Zap, UserPlus, TrendingUp, FileText, FileSignature, Banknote, ChevronDown, ChevronUp } from 'lucide-react';

const About: React.FC = () => {
  const [expandedSection, setExpandedSection] = useState<string | null>(null);

  const containerVariants: Variants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants: Variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const SectionWrapper: React.FC<{ 
    children: React.ReactNode; 
    title: string;
    icon: React.ReactElement;
  }> = ({ children, title, icon }) => {
    const isExpanded = expandedSection === title;

    return (
      <motion.div
        variants={itemVariants}
        className="bg-black bg-opacity-80 rounded-lg shadow-md p-4 mb-6 overflow-hidden w-full"
      >
        <motion.div 
          className="flex items-center justify-between cursor-pointer"
          onClick={() => setExpandedSection(isExpanded ? null : title)}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <div className="flex items-center">
            {React.cloneElement(icon, { className: "text-orange-500 mr-2", size: 24 })}
            <h2 className="text-2xl font-bold text-orange-500">{title}</h2>
          </div>
          {isExpanded ? <ChevronUp className="text-orange-500" /> : <ChevronDown className="text-orange-500" />}
        </motion.div>
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  };

  const WorkflowStep: React.FC<{ icon: React.ReactNode; text: string }> = ({ icon, text }) => (
    <motion.div 
      className="flex flex-col items-center text-center"
      whileHover={{ scale: 1.1 }}
    >
      <div className="bg-orange-500 rounded-full p-3 mb-2">
        {icon}
      </div>
      <p className="text-white text-sm">{text}</p>
    </motion.div>
  );

  const WorkflowArrow: React.FC = () => (
    <div className="text-orange-500 text-4xl font-bold">
      »
    </div>
  );

  return (
    <div className="min-h-screen bg-cover bg-center bg-no-repeat flex items-center justify-center" style={{ backgroundImage: 'url("/img-slider/logistics-background.jpg")' }}>
      <div className="bg-black bg-opacity-60 min-h-screen w-full py-8 flex items-center justify-center">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="max-w-5xl mx-auto px-4 w-full"
        >
          <motion.h1 
            variants={itemVariants} 
            className="text-4xl font-bold text-orange-500 mb-8 text-center"
          >
            О нашей компании
          </motion.h1>

          <SectionWrapper title="Кто мы" icon={<Users />}>
            <motion.p variants={itemVariants} className="mb-4 text-white">
              <span className="font-bold text-orange-500">Наша компания</span> - это <span className="font-bold text-orange-500">надежный партнер</span> в сфере логистики и управления цепочками поставок. С момента основания мы непрерывно развиваемся, расширяя спектр услуг и географию покрытия.
            </motion.p>
            <motion.ul variants={itemVariants} className="space-y-2 mb-4 text-white">
              <li className="flex items-center">
                <Truck className="mr-2 text-orange-500 flex-shrink-0" size={20} />
                <span>Комплексные решения для бизнеса любого масштаба</span>
              </li>
              <li className="flex items-center">
                <Award className="mr-2 text-orange-500 flex-shrink-0" size={20} />
                <span>От локальных доставок до сложных международных перевозок</span>
              </li>
            </motion.ul>
          </SectionWrapper>

          <SectionWrapper title="Наши преимущества" icon={<Award />}>
            <motion.ul variants={itemVariants} className="space-y-2 mb-4 text-white">
              <li className="flex items-center">
                <Users className="mr-2 text-orange-500 flex-shrink-0" size={20} />
                <span>Команда профессионалов</span>
              </li>
              <li className="flex items-center">
                <Laptop className="mr-2 text-orange-500 flex-shrink-0" size={20} />
                <span>Современные технологии</span>
              </li>
              <li className="flex items-center">
                <UserCheck className="mr-2 text-orange-500 flex-shrink-0" size={20} />
                <span>Индивидуальный подход к каждому клиенту</span>
              </li>
            </motion.ul>
            <motion.p variants={itemVariants} className="italic mt-4 text-white">
              Мы эффективно решаем самые разнообразные логистические задачи, обеспечивая <span className="font-bold text-orange-500">высокое качество сервиса и надежность</span> на каждом этапе.
            </motion.p>
          </SectionWrapper>

          <SectionWrapper title="Наша миссия" icon={<TrendingUp />}>
            <motion.p variants={itemVariants} className="mb-4 text-white">
              <span className="font-bold text-orange-500">Предоставлять клиентам</span> простые и надежные логистические решения, <span className="font-bold text-orange-500">экономя время и усилия</span>.
            </motion.p>
            <motion.ul variants={itemVariants} className="mb-4 space-y-2 text-white">
              <li className="flex items-center">
                <Clock className="mr-2 text-orange-500 flex-shrink-0" size={20} />
                <span>Каждый груз был доставлен <span className="font-bold text-orange-500">вовремя</span></span>
              </li>
              <li className="flex items-center">
                <Shield className="mr-2 text-orange-500 flex-shrink-0" size={20} />
                <span>Груз прибыл в <span className="font-bold text-orange-500">полной сохранности</span></span>
              </li>
              <li className="flex items-center">
                <Smile className="mr-2 text-orange-500 flex-shrink-0" size={20} />
                <span>Клиенты чувствовали <span className="font-bold text-orange-500">уверенность и спокойствие</span></span>
              </li>
            </motion.ul>
          </SectionWrapper>

          <SectionWrapper title="Наша цель" icon={<Zap />}>
            <motion.p variants={itemVariants} className="mb-4 text-white">
              Мы ориентированы на <span className="font-bold text-orange-500">высокие стандарты качества</span>, <span className="font-bold text-orange-500">инновации</span> и <span className="font-bold text-orange-500">индивидуальный подход</span> к каждому клиенту.
            </motion.p>
            <motion.p variants={itemVariants} className="mb-4 text-white">
              Наша главная цель — <span className="font-bold text-orange-500">стать лидером в сфере логистики</span>, предлагая услуги, которые не только удовлетворяют, но и превосходят ожидания наших клиентов.
            </motion.p>
            <motion.ul variants={itemVariants} className="mb-4 space-y-2 text-white">
              <li className="flex items-center">
                <TrendingUp className="mr-2 text-orange-500 flex-shrink-0" size={20} />
                <span>Стремление к высочайшему качеству услуг</span>
              </li>
              <li className="flex items-center">
                <Zap className="mr-2 text-orange-500 flex-shrink-0" size={20} />
                <span>Внедрение инновационных решений</span>
              </li>
              <li className="flex items-center">
                <UserPlus className="mr-2 text-orange-500 flex-shrink-0" size={20} />
                <span>Персонализированный подход к каждому клиенту</span>
              </li>
            </motion.ul>
          </SectionWrapper>

          <SectionWrapper title="Как мы работаем" icon={<Truck />}>
            <motion.div 
              variants={itemVariants}
              className="flex flex-col md:flex-row justify-between items-center mt-4"
            >
              <WorkflowStep 
                icon={<FileText className="text-white" size={24} />}
                text="Оставьте заявку"
              />
              <WorkflowArrow />
              <WorkflowStep 
                icon={<FileSignature className="text-white" size={24} />}
                text="Заключите Договор"
              />
              <WorkflowArrow />
              <WorkflowStep 
                icon={<Truck className="text-white" size={24} />}
                text="Получите услугу"
              />
              <WorkflowArrow />
              <WorkflowStep 
                icon={<Banknote className="text-white" size={24} />}
                text="Оплатите услуги"
              />
            </motion.div>
          </SectionWrapper>
        </motion.div>
      </div>
    </div>
  );
};

export default About;