import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from '.././components/Slider';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight, CheckCircle, Truck, Award, Star } from 'lucide-react';

const Home: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [name, setName] = useState('');
  const [fromAddress, setFromAddress] = useState('');
  const [toAddress, setToAddress] = useState('');

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const staggerChildren = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Заявка отправлена:', { name, fromAddress, toAddress });
  };

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800">
      <Slider />
    
      <div className="max-w-7xl mx-auto px-4 py-12">
        <motion.div 
          className="flex justify-between items-center mb-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <motion.h1 
            className="text-5xl font-bold text-center flex-grow"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            Добро пожаловать в нашу компанию
          </motion.h1>
        </motion.div>
      
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <motion.div 
            className="shadow-lg rounded-lg overflow-hidden bg-white"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <div className="p-8">
              <motion.p 
                className="text-xl mb-6 leading-relaxed"
                variants={fadeIn}
                initial="hidden"
                animate={isVisible ? "visible" : "hidden"}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                Мы специализируемся на предоставлении высококачественных услуг для наших клиентов. 
                Наша команда экспертов готова помочь вам в достижении ваших целей.
              </motion.p>
            
              <motion.h2 
                className="text-3xl font-semibold mb-6 flex items-center"
                variants={fadeIn}
                initial="hidden"
                animate={isVisible ? "visible" : "hidden"}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                <Award className="mr-2 text-orange-500" />
                Наши преимущества:
              </motion.h2>

              <motion.ul 
                className="grid grid-cols-1 gap-4 mb-8"
                variants={staggerChildren}
                initial="hidden"
                animate={isVisible ? "visible" : "hidden"}
              >
                {[
                  "Профессиональный подход",
                  "Индивидуальные решения",
                  "Современные технологии",
                  "Поддержка 24/7"
                ].map((item, index) => (
                  <motion.li 
                    key={index}
                    className="flex items-center text-lg"
                    variants={fadeIn}
                    whileHover={{ scale: 1.05, originX: 0 }}
                  >
                    <CheckCircle className="text-green-500 mr-2" />
                    {item}
                  </motion.li>
                ))}
              </motion.ul>
            
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 1 }}
              >
                <Link 
                  to="/services" 
                  className="inline-flex items-center bg-orange-500 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-orange-600 transition duration-300 transform hover:scale-105"
                >
                  Узнать больше о наших услугах
                  <ArrowRight className="ml-2" />
                </Link>
              </motion.div>
            </div>
          </motion.div>

          <motion.div
            className="shadow-lg rounded-lg overflow-hidden bg-white"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <div className="p-8">
              <h2 className="text-3xl font-semibold mb-6 flex items-center">
                <Truck className="mr-2 text-orange-500" />
                Оформить заявку
              </h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium mb-1">
                    Ваше имя
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-orange-500 bg-white border-gray-300"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="fromAddress" className="block text-sm font-medium mb-1">
                    Адрес отправления
                  </label>
                  <input
                    type="text"
                    id="fromAddress"
                    value={fromAddress}
                    onChange={(e) => setFromAddress(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-orange-500 bg-white border-gray-300"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="toAddress" className="block text-sm font-medium mb-1">
                    Адрес доставки
                  </label>
                  <input
                    type="text"
                    id="toAddress"
                    value={toAddress}
                    onChange={(e) => setToAddress(e.target.value)}
                    className="w-full px-4 py-2 border rounded-md focus:ring-2 focus:ring-orange-500 bg-white border-gray-300"
                    required
                  />
                </div>
                <motion.button
                  type="submit"
                  className="w-full bg-orange-500 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-orange-600 transition duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Отправить заявку
                </motion.button>
              </form>
            </div>
          </motion.div>
        </div>

        <motion.div
          className="mt-12 text-center"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <h2 className="text-3xl font-semibold mb-6 flex items-center justify-center">
            <Star className="mr-2 text-yellow-500" />
            Почему выбирают нас
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { title: "Быстрая доставка", icon: "🚀" },
              { title: "Надежность", icon: "🛡️" },
              { title: "Лучшие цены", icon: "💰" }
            ].map((item, index) => (
              <motion.div
                key={index}
                className="p-6 rounded-lg bg-white shadow-lg"
                whileHover={{ scale: 1.05 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <div className="text-4xl mb-4">{item.icon}</div>
                <h3 className="text-xl font-semibold">{item.title}</h3>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Home;