import React from 'react';

const AdditionalServices: React.FC = () => {
  return (
    <div className="mb-6">
      <h3 className="font-semibold mb-2 text-gray-700">Дополнительные услуги</h3>
      <div className="space-y-2">
        <div className="flex items-center">
          <input type="checkbox" id="insurance" name="insurance" className="mr-2" />
          <label htmlFor="insurance">Страхование груза</label>
        </div>
        <div className="flex items-center">
          <input type="checkbox" id="packaging" name="packaging" className="mr-2" />
          <label htmlFor="packaging">Дополнительная упаковка</label>
        </div>
        <div className="flex items-center">
          <input type="checkbox" id="pallets" name="pallets" className="mr-2" />
          <label htmlFor="pallets">Упаковка в паллеты</label>
        </div>
        <div className="flex items-center">
          <input type="checkbox" id="loading" name="loading" className="mr-2" />
          <label htmlFor="loading">Погрузка на месте</label>
        </div>
        <div className="flex items-center">
          <input type="checkbox" id="unloading" name="unloading" className="mr-2" />
          <label htmlFor="unloading">Разгрузка на месте</label>
        </div>
        <div className="flex items-center">
          <input type="checkbox" id="documents" name="documents" className="mr-2" />
          <label htmlFor="documents">Возврат документов</label>
        </div>
      </div>
    </div>
  );
};

export default AdditionalServices;