import React, { useEffect } from 'react';

interface SiteMetadataProps {
  title: string;
  favicon: string;
}

const SiteMetadata: React.FC<SiteMetadataProps> = ({ title, favicon }) => {
  useEffect(() => {
    // Установка заголовка страницы
    document.title = title;
    
    // Установка или обновление favicon
    const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/png'; // Изменено на корректный MIME-тип для PNG
    link.rel = 'icon';
    link.href = favicon; // favicon должен быть путем к файлу, например '/logo mini.png'
    document.getElementsByTagName('head')[0].appendChild(link);
  }, [title, favicon]);

  return null;
};

export default SiteMetadata;