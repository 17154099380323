import React, { useState } from 'react';

interface Document {
  id: string;
  title: string;
  description: string;
  fileUrl: string;
  fileType: 'pdf' | 'word';
  purpose: string;
}

const documents: Document[] = [
  {
    id: '1',
    title: 'Наши Реквизиты',
    description: 'Реквизиты нашей компании ООО',
    fileUrl: '/documents/OOO Paletkin.pdf',
    fileType: 'pdf',
    purpose: 'Этот документ содержит официальные реквизиты нашей компании. Он необходим для заключения договоров, выставления счетов и других официальных взаимодействий с партнерами и клиентами.'
  },
  {
    id: '2',
    title: 'Форма накладной',
    description: 'Шаблон накладной для заполнения',
    fileUrl: '/documents/Товарно-транспортная накладная.docx',
    fileType: 'word',
    purpose: 'Данный шаблон используется для создания товарно-транспортных накладных. Он необходим для оформления перевозки грузов и подтверждения их передачи от отправителя к получателю.'
  },
  {
    id: '3',
    title: 'Шаблон договора траспортного экспедирования',
    description: 'Типовой договор на оказание транспортных услуг',
    fileUrl: '/documents/Договор транспортной экспедиции.docx',
    fileType: 'word',
    purpose: 'Этот документ представляет собой типовой шаблон договора на оказание транспортных услуг. Он содержит все необходимые разделы и пункты, соответствующие текущему законодательству. Шаблон можно использовать как основу для составления индивидуального договора, адаптируя его под конкретные условия сделки.'
  },
  {
    id: '4',
    title: 'Договор-заявка',
    description: 'Комбинированный документ для быстрого оформления заказа',
    fileUrl: '/documents/Договор-Заявка.docx',
    fileType: 'word',
    purpose: 'Договор-заявка - это универсальный документ, объединяющий в себе элементы договора и заявки на перевозку. Он позволяет быстро оформить заказ на транспортные услуги, содержит основные условия перевозки и служит юридическим основанием для выполнения заказа.'
  },
  {
    id: '5',
    title: 'Доверенности',
    description: 'Различные виды доверенностей',
    fileUrl: '',
    fileType: 'word',
    purpose: 'Этот раздел содержит различные виды доверенностей, необходимых для осуществления транспортных операций.'
  }
];

const powerOfAttorneyDocs = [
  { id: '5a', title: 'Доверенность универсальная', fileUrl: '/documents/Доверенность универсальная.docx' },
  { id: '5b', title: 'Доверенность на получение передачу паллет', fileUrl: '/documents/Доверенность на получение передачу паллет.docx' },
  { id: '5c', title: 'Доверенность на забор паллет', fileUrl: '/documents/Доверенность на забор паллет.docx' },
];

const Documents: React.FC = () => {
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const [expandedSection, setExpandedSection] = useState<string | null>(null);

  const handleDownload = (url: string, id: string, fileType: 'pdf' | 'word') => {
    setActiveButton(id);
    
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}${url}`;
    link.target = '_blank';
    link.download = url.split('/').pop() || `document.${fileType}`;
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    setTimeout(() => setActiveButton(null), 1000);
  };

  const toggleSection = (id: string) => {
    setExpandedSection(expandedSection === id ? null : id);
  };

  return (
    <div className="container mx-auto py-8 mt-20">
      <h1 className="text-3xl font-bold mb-6 text-orange-500 text-center">Документы для перевозки груза</h1>
      <div className="flex flex-col md:flex-row gap-6 justify-center">
        <div className="md:w-2/3">
          {documents.map((doc) => (
            <div key={doc.id} className="mb-6">
              <div className="flex items-start">
                <div className="flex-grow pr-4">
                  <h2 className="text-xl font-semibold mb-2 underline">{doc.title}</h2>
                  <p className="text-gray-600 mb-2">{doc.description}</p>
                  <p className="text-sm text-gray-700">{doc.purpose}</p>
                </div>
                {doc.id !== '5' && (
                  <div className="flex-shrink-0 w-1/3">
                    <button 
                      onClick={() => handleDownload(doc.fileUrl, doc.id, doc.fileType)}
                      className={`w-full font-bold py-2 px-4 rounded transition-colors duration-300 
                        ${activeButton === doc.id
                          ? 'bg-gray-500 text-white'
                          : 'bg-orange-500 hover:bg-gray-500 text-white'
                        }`}
                    >
                      Скачать {doc.fileType === 'pdf' ? 'PDF' : 'Word'}
                    </button>
                  </div>
                )}
                {doc.id === '5' && (
                  <div className="flex-shrink-0 w-1/3">
                    <button 
                      onClick={() => toggleSection(doc.id)}
                      className="w-full font-bold py-2 px-4 rounded bg-orange-500 hover:bg-gray-500 text-white transition-colors duration-300"
                    >
                      {expandedSection === doc.id ? 'Свернуть' : 'Развернуть'}
                    </button>
                  </div>
                )}
              </div>
              {doc.id === '5' && expandedSection === doc.id && (
                <div className="mt-4 ml-4">
                  {powerOfAttorneyDocs.map((subDoc) => (
                    <div key={subDoc.id} className="flex items-center mb-2">
                      <span className="flex-grow">{subDoc.title}</span>
                      <button 
                        onClick={() => handleDownload(subDoc.fileUrl, subDoc.id, 'word')}
                        className="ml-2 px-3 py-1 bg-orange-500 hover:bg-gray-500 text-white rounded transition-colors duration-300"
                      >
                        Скачать Word
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Documents;