import React from 'react';

const SenderInfo: React.FC = () => {
  return (
    <div className="mb-6">
      <h3 className="font-semibold mb-2 text-gray-700">ИНФОРМАЦИЯ ОТПРАВИТЕЛЯ</h3>
      <div className="space-y-4">
        <input type="text" name="lastName" placeholder="Фамилия" className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-orange-500 focus:border-orange-500" />
        <input type="text" name="firstName" placeholder="Имя" className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-orange-500 focus:border-orange-500" />
        <input type="text" name="organization" placeholder="Организация" className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-orange-500 focus:border-orange-500" />
        <input type="tel" name="phone" placeholder="+7 (_ _ _) _ _ _ - _ _ - _ _" className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-orange-500 focus:border-orange-500" />
      </div>
    </div>
  );
};

export default SenderInfo;